import React, { useState, useEffect } from 'react';
import axios from 'axios'; import { useNavigate } from 'react-router-dom'; // Importa useNavigate para la navegación
import '../../Estilos/style_Iot_cliente.css';
import ledLightOnImage from '../../imagenes/led_1.png'; // Asegúrate de tener esta imagen
import ledLightOffImage from '../../imagenes/led_2.png'; // Asegúrate de tener esta imagen
import { useParams } from 'react-router-dom';

const LedWidget = ({ claveDispositivo, ledId }) => {
  const [isOn, setIsOn] = useState(false);

  // Fetch LED state from the backend
  const fetchLedState = async () => {
    try {
      const response = await axios.get(`https://backendincub.onrender.com/api/dispositivos/estado/${claveDispositivo}`);
      if (response.data && response.data.estados && ledId in response.data.estados) {
        setIsOn(response.data.estados[ledId]);
      }
    } catch (error) {
      console.error('Error al obtener el estado del LED:', error);
    }
  };

  useEffect(() => {
    fetchLedState();
    // Intervalo para actualizar el estado del LED
    const intervalId = setInterval(fetchLedState, 1000); // Ajusta según necesidad
    return () => clearInterval(intervalId);
  }, [claveDispositivo, ledId]);

  const toggleLed = async () => {
    const newState = !isOn;
    setIsOn(newState); // Cambia el estado optimistamente

    try {
      await axios.post('https://backendincub.onrender.com/api/dispositivos/comando', {
        claveDispositivo,
        comando: `${ledId.toUpperCase()}:${newState ? 'ON' : 'OFF'}`,
      });
    } catch (error) {
      console.error('Error al cambiar el estado del LED:', error);
      // Opcionalmente, revertir el cambio si el comando falló
      setIsOn(!newState);
    }
  };
  return (
    <div className="card">
      <h2>Foco {ledId.toUpperCase()}</h2>
      <img
        style={{ width: '150px' }}
        src={isOn ? ledLightOnImage : ledLightOffImage}
        alt={`Foco ${ledId.toUpperCase()} ${isOn ? 'encendido' : 'apagado'}`}
        onClick={toggleLed}
      />
      <button className={`led-button ${isOn ? 'on' : 'off'}`} onClick={toggleLed}>
        {isOn ? 'Apagar' : 'Encender'}
      </button>
    </div>
  );
};



const TemperatureWidget = ({ temperatura }) => {
  // Redondea la temperatura a un decimal
  const roundedTemp = Math.round(temperatura * 10) / 10;
  const [temperature, setTemperature] = useState(temperatura); // Puedes cambiar esto para probar diferentes temperaturas
  const fillHeight = ((roundedTemp - 18) / (45 - 18)) * 130;

  // Determinar el color en función del rango de temperatura
  let fillColor;
  if (temperature < 30) {
    fillColor = '#007bff'; // Azul para temperaturas bajas
  } else if (temperature >= 30 && temperature <= 37) {
    fillColor = '#4CAF50'; // Verde para temperaturas medias
  } else {
    fillColor = '#dc3545'; // Rojo para temperaturas altas
  }

  return (
    <div className="card temperature-card">
      <h2>Temperatura</h2>
      <svg width="50" height="160" viewBox="0 0 50 160" className="temperature-svg">
        <rect x="15" y="10" width="20" height="130" rx="10" ry="10" fill="none" stroke="#ccc" strokeWidth="2" />
        <rect x="15" y={140 - fillHeight} width="20" height={fillHeight} rx="10" ry="10" fill={fillColor} />
        <circle cx="25" cy="140" r="15" fill={fillColor} />
      </svg>
      <p>{roundedTemp} °C</p>
    </div>
  );
};


const HumidityWidget = ({ humedad }) => {

  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const offset = ((100 - humedad) / 100) * circumference;
  return (
    <div className="card">
      <h2>Humedad</h2>
      <div className="humidity-indicator">
        <div className="humidity-widget">
          <svg width="120" height="120" className="humidity-circle">
            <circle
              stroke="#e6e6e6"
              fill="transparent"
              strokeWidth="10"
              r={radius}
              cx="60"
              cy="60"
            />
            <circle
              stroke="#00f"
              fill="transparent"
              strokeWidth="10"
              strokeDasharray={circumference}
              strokeDashoffset={offset}
              r={radius}
              cx="60"
              cy="60"
            />
          </svg>
          <div className="humidity-percentage">{humedad}%</div>
        </div>
        <div style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <center>
            <p>{humedad} %</p>
          </center>
        </div>
      </div>
    </div>
  );
};

const TankStatusWidgetInter = ({ nivelI }) => {
  let tankLevelPercentage;
  let tankColor;
  let tankText;

  if (nivelI === "Lleno") {
    tankLevelPercentage = 95; // Tanque completamente lleno
    tankColor = "#4CAF50"; // Color verde para lleno
    tankText = "100%"; // Texto para mostrar dentro del tanque
  } else {
    tankLevelPercentage = 15; // Tanque vacío
    tankColor = "#dc3545"; // Color rojo para vacío
    tankText = "0%"; // Texto para mostrar dentro del tanque
  }

  // Ajusta la altura del agua en el tanque según el porcentaje
  const tankWaterStyle = {
    backgroundColor: tankColor,
    height: `${tankLevelPercentage}%`,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center', // Centra el texto verticalmente
    justifyContent: 'center', // Centra el texto horizontalmente
    color: 'white', // Color del texto
    fontWeight: 'bold',
  };

  // Estilo del contenedor del tanque
  const containerStyle = {
    border: '3px solid #ccc',
    height: '300px',
    borderRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
    width: '180px',
    backgroundColor: '#ffffff',
    boxShadow: 'inset 0 -3px 3px',
    display: 'flex',
    flexDirection: 'column-reverse',
    margin: '10px',
  };

  return (
    <div className="card">
      <h2 style={{ textAlign: 'center' }}>Estado del Tanque Interior</h2>
      <div className="tank-status-container" style={containerStyle}>
        <div className="tank-water" style={tankWaterStyle}>
          {tankLevelPercentage > 0 && tankText} {/* Solo muestra el texto si el tanque no está vacío */}
        </div>
      </div>
      <p>Nivel del Tanque Interior: {nivelI}</p>
    </div>
  );
};


const TankStatusWidgetExt = ({ nivelE }) => {
  let tankLevelPercentage;
  let tankColor;

  switch (nivelE) {
    case "Muy lleno":
      tankLevelPercentage = 95;
      tankColor = "#0033cc";
      break;
    case "Lleno":
      tankLevelPercentage = 75;
      tankColor = "#3366ff";
      break;
    case "Medio":
      tankLevelPercentage = 50;
      tankColor = "#339933";
      break;
    case "Bajo":
      tankLevelPercentage = 25;
      tankColor = "#ff9933";
      break;
    case "Vacío":
      tankLevelPercentage = 5;
      tankColor = "#cc3300";
      break;
    default:
      tankLevelPercentage = 0;
      tankColor = "#808080";
  }
  
  // Ajusta la altura del agua en el tanque según el porcentaje
  const tankWaterStyle = {
    backgroundColor: tankColor,
    height: `${tankLevelPercentage}%`,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'flex-end', // Alinea el contenido (texto) al fondo del div
    justifyContent: 'center', // Centra el texto horizontalmente
    color: 'white', // Asegúrate de que el texto sea visible sobre el color del fondo
    fontWeight: 'bold',
  };

  // Estilo del contenedor del tanque
  const containerStyle = {
    border: '3px solid #ccc',
    height: '300px',
    borderRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
    width: '180px',
    backgroundColor: '#ffffff',
    boxShadow: 'inset 0 -3px 3px',
    display: 'flex',  
    flexDirection: 'column-reverse',
    margin: '10px',
  };

  return (
    <div className="card">
      <h2 style={{ textAlign: 'center' }}>Estado del Tanque Exterior</h2>
      <div className="tank-status-container" style={containerStyle}>
        <div className="tank-water" style={tankWaterStyle}>
          {/* Muestra el porcentaje del nivel del tanque dentro del div de agua */}
          {tankLevelPercentage}%
        </div>
      </div>
      <p>Nivel del Tanque Exterior: {nivelE}</p>
    </div>
  );
};




const Decive = () => {
  const { claveDispositivo } = useParams();
  const [dispositivo, setDispositivo] = useState();
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  // Función para obtener los datos del dispositivo desde el backend
  const fetchDeviceData = () => {
    axios.get(`https://backendincub.onrender.com/api/incubadora/${claveDispositivo}`)
      .then(response => {
        console.log('Datos del dispositivo obtenidos:', response.data);
        setDispositivo(response.data); // Actualiza el estado con los nuevos datos
      })
      .catch(error => {
        console.error('Error al obtener la información del dispositivo', error);
      });
  };

  useEffect(() => {
    fetchDeviceData();
    const intervalId = setInterval(fetchDeviceData, 300); // Establecer el intervalo para actualizar cada segundo
    return () => clearInterval(intervalId);
  }, [claveDispositivo]);

  if (!dispositivo) {
    return <p>Cargando información del dispositivo...</p>;
  }
  const { estados, sensores } = dispositivo;
  const tankLevelE = estados.tanqueExterior;
  const tankLevelI = estados.tanqueInterior;


  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="control-page">
        <button onClick={goBack} className="back-button">Atrás</button>
        <div className='name'><h1>{dispositivo.nombre}</h1></div>
        <div className="device">
          <div className="device-info">
            <img className="product-image" src={dispositivo.imagenUrl} alt="Incubadora" />
            <h2> Tu incubadora</h2>
          </div>
          <div className="device-controls">
            <div className='p1'>
              <div className='device-focos'>
                {dispositivo && dispositivo.estados && (
                  <>
                    <LedWidget
                      claveDispositivo={claveDispositivo}
                      ledId="led1"
                      estadoInicial={dispositivo.estados.led1}
                    />
                    <LedWidget
                      claveDispositivo={claveDispositivo}
                      ledId="led2"
                      estadoInicial={dispositivo.estados.led2}
                    />
                  </>
                )}

              </div>
              <div className='device-tem'>
                <TemperatureWidget temperatura={sensores.temperatura} />
                <HumidityWidget humedad={sensores.humedad} />
              </div>
            </div>
            <div className='p2'>
              <div className='device-tnq'>
                <TankStatusWidgetExt nivelE={tankLevelE} />
                <TankStatusWidgetInter nivelI={tankLevelI} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div >
  );
};
export default Decive;

